import { DatePipe, Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageStatus } from '@core/enums/message-status.enum';
import { Pagination } from '@core/models/pagination.model';
import { DateFilterService } from '@core/services/date-filter.service';
import { SeoService } from '@core/services/seo.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { BehaviorSubject, forkJoin, of, Subscription, Subject } from 'rxjs';
import { delay, map, tap, takeUntil, catchError } from 'rxjs/operators';
import { HistoryDataService } from '../../services/history-data.service';
import { RewardDataService } from '../../services/reward-data.service';
import { PromotionContentHttpService } from '@views/modules/promotion/services/promotion-content-http.service';
import { PromotionCancelModalComponent } from '../../dialogs/promotion-cancel-modal/promotion-cancel-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { TransactionReceiptModalComponent } from '../../dialogs/transaction-receipt-modal/transaction-receipt-modal.component';
import { PortalDepositHttpService } from '@core/services/portal-deposit-http.service';
import Swal from 'sweetalert2';
import { RequestReceiptHttpService } from '@core/services/request-receipt-http.service';
import { RequestReceipt } from '@core/models/request-receipt.model';
import svgIconList from 'assets/icons.json';
declare var $: any;

@Component({
  selector: 'app-portal-history',
  templateUrl: './portal-history.component.html',
  styleUrls: ['./portal-history.component.scss']
})
export class PortalHistoryComponent implements OnInit, OnDestroy, AfterViewInit {
  svg: any = svgIconList;

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;

  form: FormGroup;
  status = MessageStatus;
  activeSubtab = parseInt(this.activatedRoute.snapshot.paramMap.get('tab'), 10) ? parseInt(this.activatedRoute.snapshot.paramMap.get('tab'), 10) : 1;
  history$ = [];
  rewards$ = [];
  destroy$ = new Subject<void>();
  tab: number;
  dataSub$ = new BehaviorSubject<any>(null);
  userData = JSON.parse(localStorage.getItem('user_data'));
  isSuccess = this.depositHttpService.isSuccess;
  messages$ = this.depositHttpService.messages$;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  activeDateFilter = 'All';
  filterButtons = ['Today', 'Last30', 'Yesterday', 'Last60', 'Last7', 'All'];
  isSearching = false;
  currencyCode = JSON.parse(localStorage.getItem('user_data')).currency.code;
  isLoading = false;
  historyType = {
    'transaction': 0,
    'promotion': 1,
    'bet': 2,
    'rebate': 3,
    'reward': 4,
  };
  activeType: any;
  currentTab: number;
  max = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  historyTabs = [
    this.translateService.instant('Transaction History'), this.translateService.instant('Activated Promotion'),
    this.translateService.instant('Betting Summary'), this.translateService.instant('Rebate & Cashback'),
    this.translateService.instant('Reward History')
  ];
  private datePickerSubscription = new Subscription();
  activePromotionList = [];
  newRequestIncoming = this.requestReceiptHttpService.newRequestIncoming;
  depositData: RequestReceipt = null;

  sortingSelection = {
    'sort_by': 'updated_at',
    'sort_order': 'desc',
  };

  constructor(
    private loadingBar: LoadingBarService,
    private historyDataService: HistoryDataService,
    private activatedRoute: ActivatedRoute,
    private dateFilterService: DateFilterService,
    private datePipe: DatePipe,
    private router: Router,
    private location: Location,
    private rewardDataService: RewardDataService,
    private translateService: TranslateService,
    private seoService: SeoService,
    private promotionContentHttpService: PromotionContentHttpService,
    public dialog: MatDialog,
    private gameCategoryHttpService: GameCategoryHttpService,
    private depositHttpService: PortalDepositHttpService,
    private requestReceiptHttpService: RequestReceiptHttpService,
  ) { }

  ngOnInit() {
    this.activeType = this.activatedRoute.snapshot.paramMap.get('type');
    this.currentTab = this.historyType[this.activeType];
    this.onScrollMenu(this.currentTab);
    this.formInit();
    this.reload();
    this.pagination = this.historyDataService.pagination;
    if (window.location.pathname.includes('reward')) { // for auto scroll on last item
      of(null).pipe(
        delay(100), tap(() => $(document).find('.mat-tab-header-pagination-after').click()
        )).subscribe();
    }
    // this.historyDataService.dataUpdated$.subscribe(() => {
    //   this.reload();
    // })
    this.depositData = RequestReceiptHttpService.requestReceipt;
    this.newRequestIncoming.subscribe(() => {
      this.depositData = RequestReceiptHttpService.requestReceipt;
      this.reload();
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();  
    this.datePickerSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'start_date'),
      this.buildDatePicker(1, 'end_date'),
    ]).subscribe();
  }

  // @HostListener('window:scroll', [''])
  // onScroll(e: Event) {
  //   if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
  //     if (this.pagination && this.page <= this.pagination.last_page) {
  //       this.isSearching = true;
  //       this.page++;
  //       this.onViewPageBy(true, this.page, this.pageSize);
  //     }
  //   }
  // }

  onScroll(event) {
    if ((event.target.offsetHeight + event.target.scrollTop + 1) >= event.target.scrollHeight) {
      if (this.page < this.pagination.last_page && !this.isSearching) {
        this.isSearching = true;
        this.page++;
        this.onViewPageBy(true, this.page, this.pageSize);
      }
    }
  }

  onChangeTab(index: number) {
    this.onScrollMenu(index);
    this.page = 1;
    this.rewards$ = [];
    this.history$ = [];
    this.currentTab = index;
    var key = Object.keys(this.historyType)[this.currentTab];
    this.location.replaceState(`/member/history/${key}`);
    this.onViewPageBy(true, this.page, this.pageSize);
    this.seoService.execute();
  }

  onDateTimeFilter(type?: any) {
    this.activeDateFilter = type;
    if (type === 'Today') {
      this.form.patchValue({
        start_date: this.dateFilterService.getToday().from,
        end_date: this.dateFilterService.getToday().to
      });
    } else if (type === 'Last30') {
      this.form.patchValue({
        start_date: this.dateFilterService.getLast30Days().from,
        end_date: this.dateFilterService.getLast30Days().to
      });
    } else if (type === 'Yesterday') {
      this.form.patchValue({
        start_date: this.dateFilterService.getYesterday().from,
        end_date: this.dateFilterService.getYesterday().to
      });
    } else if (type === 'Last60') {
      this.form.patchValue({
        start_date: this.dateFilterService.getLast60Days().from,
        end_date: this.dateFilterService.getLast60Days().to
      });
    } else if (type === 'Last7') {
      this.form.patchValue({
        start_date: this.dateFilterService.getLast7Days().from,
        end_date: this.dateFilterService.getLast7Days().to
      });
    } else {
      this.form.patchValue({
        start_date: null,
        end_date: null
      });
    }
    this.onSubmit();
  }

  onViewPageBy(preLoad?: boolean, page = 1, pageSize?: number, params?: string) {
    this.isLoading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    return this.setHistory(preLoad, this.activeSubtab, page, pageSize, params);
  }

  onSubmit() {
    const data = this.filterFormFields(this.form.value);
    this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.setHistory(false, this.activeSubtab, 1, this.pageSize, parameters);
  }

  onSelectSubtab(tab: number) {
    this.destroy$.next(); //avoid displaying wrong data when switching tab too quickly
    this.page = 1;
    this.params = '';
    this.form.patchValue({
      start_date: null,
      end_date: null
    });
    this.activeSubtab = tab;
    this.onViewPageBy(false, this.page);
  }

  onViewDetails(id: number) {
    var key = Object.keys(this.historyType)[this.currentTab];
    this.router.navigateByUrl(`/member/history/${key}/${id}`);
  }

  onViewWithdrawalDetails(event, id: number, typeId?: string) {
    const clickedElement = event.target as HTMLElement;

    if (typeId == '2' && !clickedElement.classList.contains('exclude-click')) {
      this.router.navigateByUrl(`/member/history/withdrawal/${id}`);
    }
  }

  onFormDate(formKey: string, separator = '/') {
    const value = this.form.get(formKey).value;
    if (value !== null) {
      let date = this.form.get(formKey).value.replace(/[^0-9]+/g, "");
      let newDate = '';
      for (var i = 0; i < date.length; i++) {
        if (i === 4 || i === 6) {
          newDate += separator;
        }
        newDate += date[i];
      }
      this.form.patchValue({ [formKey]: newDate })
    }
  }

  onScrollMenu(index: number) {
    of(null).pipe(
      delay(100), tap(() => {
        var elem = document.querySelector('#tab' + index);
        var bounding = elem.getBoundingClientRect();
        if (+bounding.x < 50) {
          $('.portalHistory').animate({ scrollLeft: '-=' + bounding.x.toString().replace('-', '') }, 500);
        } else {
          $('.portalHistory').animate({ scrollLeft: '+=' + bounding.x.toString().replace('-', '') }, 500);
        }
      }
      )).subscribe();
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, this.dateFilterService.dateFormat)),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      })
    );
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = key === 'start_date' || key === 'end_date' ? moment(formData[key]).format('YYYY-MM-DD') : formData[key];
      }
    });
    return fields;
  }

  private async setHistory(preLoad: boolean, activeSubtab: number, page: number, pageSize: number, params: string) {
    if (!preLoad) {
      this.history$ = [];
    }
    switch (this.currentTab) {
      // tab = Transaction History
      case 0:
        if (activeSubtab === 1) {
          this.historyDataService.getHistoryWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
            takeUntil(this.destroy$),
            tap(res => {
              this.pagination = this.historyDataService.pagination;
              this.dataSub$.next(res);
              this.loadingBar.complete();

              // TODO: Revise this map block!
              res.map((row: any) => {
                this.history$.push({
                  ...row,
                  created_at: moment(row.created_at).local(true).format('YYYY-MM-DD HH:mm')
                });
              });
              this.isSearching = false;
              this.isLoading = false;
            })
          ).subscribe();
        } else {
          this.historyDataService.getTransfer(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
            takeUntil(this.destroy$),
            tap(res => {
              this.pagination = this.historyDataService.pagination;
              this.dataSub$.next(res);
              this.loadingBar.complete();

              // TODO: Revise this map block!
              res.map((row: any) => {
                this.history$.push({
                  ...row,
                  created_at: moment(row.updated_at).local(true).format('YYYY-MM-DD HH:mm:ss') // Use updated_at value use as transfer complete time cause it might different with created_at
                });
              });
              this.isSearching = false;
              this.isLoading = false;
            })
          ).subscribe();
        }
        break;
      // tab = Activated Promotion
      case 1:
        this.activePromotionList = await new Promise(resolve => {
          this.promotionContentHttpService.getActivePromotionList().subscribe(res => {
            resolve(res);
          });
        });
        this.historyDataService.getPromotionHistory(`?page=${page}&perPage=${pageSize}`).pipe(
          takeUntil(this.destroy$),
          tap(res => {
            this.history$ = [...this.history$, ...res]
            this.pagination = this.historyDataService.pagination;
            this.dataSub$.next(res);
            this.loadingBar.complete();
            this.isSearching = false;
            this.isLoading = false;
          })
        ).subscribe();
        break;
      // tab = Betting Summary
      case 2:
        const start_date = this.dateFilterService.getLastMonthWithTime().from,
          end_date = this.dateFilterService.getLastMonthWithTime().to;
        this.historyDataService.getBetHistory(`?page=${page}&perPage=${pageSize}&start_date_time=${start_date}&end_date_time=${end_date}`).pipe(
          takeUntil(this.destroy$),
          tap(res => {
            this.history$ = [...this.history$, ...res]
            this.pagination = this.historyDataService.pagination;
            this.dataSub$.next(res);
            this.loadingBar.complete();
            this.isSearching = false;
            this.isLoading = false;
          })
        ).subscribe();

        break;
      // tab = Rebate & Cashback
      case 3:
        this.historyDataService.getRebateHistory(`?page=${page}&perPage=${pageSize}`).pipe(
          takeUntil(this.destroy$),
          tap(res => {
            this.history$ = [...this.history$, ...res]
            this.pagination = this.historyDataService.pagination;
            this.dataSub$.next(res);
            this.loadingBar.complete();
            this.isSearching = false;
            this.isLoading = false;
          })
        ).subscribe();
        ; break;
      // tab = Rewards History
      case 4:
        this.rewardDataService.getWithQuery(`?page=${page}&perPage=${pageSize}&active=0`).pipe(
          takeUntil(this.destroy$),
          tap(res => {
            this.rewards$ = [...this.rewards$, ...res]
            this.pagination = this.rewardDataService.pagination;
            this.dataSub$.next(res);
            this.loadingBar.complete();
            this.isSearching = false;
            this.isLoading = false;
          })
        ).subscribe();
        break
    }
  }

  checkCancelable(item: any) {
    if (item.allow_cancel) {
      if (this.activePromotionList.length > 0) {
        // Active Promotion, Low Balance 1, Low Balance 2
        if (item.status == 1 || item.status == 8 || item.status == 9) {
          const data = this.activePromotionList.find(x => x.promotion_id === item.promotion_id);
          if (data) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onOpenDialog(type: string, item: any) {
    if (type === 'cancel'){
      const detail = item;
      const memberPromotion = this.activePromotionList.find(x => x.promotion_id === detail.promotion_id);
      this.loadingBar.start();
      this.promotionContentHttpService.getBonusWinAmount(memberPromotion.id).subscribe(res => {
        this.gameCategoryHttpService.getCategoryList().subscribe(gameres => {
          this.loadingBar.complete();
          const dialogRef = this.dialog.open(PromotionCancelModalComponent, {
            width: '500px',
            data: {
              detail: memberPromotion,
              amount: res,
              gameWalletBalance: +gameres['total_game_balance']
            }
          });
  
          dialogRef.afterClosed().subscribe(async (result) => {
            if(result === true) {
              this.activePromotionList = [];
              this.activePromotionList = await new Promise(resolve => {
                this.promotionContentHttpService.getActivePromotionList().subscribe(res => {
                  resolve(res);
                });
              });
              this.reload();
            }
          });
        });
      });
    }
  }

  private reload() {
    this.onViewPageBy(false, this.page);
  }

  private formInit() {
    this.form = new FormGroup({
      start_date: new FormControl(null),
      end_date: new FormControl(null)
    });
  }
  
  openReceipt(transaction: any) {
    let paths = [];

    if( transaction.type_id == 1 ) {
      paths = transaction.deposit_receipts;
    } else {
      if (transaction.bank_transactions && transaction.bank_transactions.length > 0) {
        Object.keys(transaction.bank_transactions).forEach((key) => {
          paths.push(transaction.bank_transactions[key]);
        });
      }
    }

      this.dialog.open(TransactionReceiptModalComponent, {
        width: '800px',
        data: {
          member_data: this.userData,
          receipt_path: paths,
          transaction: transaction
        }
      });
  }

  cancelDeposit() {
    Swal.fire({
      html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
        '<div class="text-center m-t-20">' +
        '<ul><li class="mt-4">' + this.translateService.instant('Are you sure you want to cancel this deposit submission?') + '</li></ul>' +
        '</div>',
      confirmButtonText: this.translateService.instant('YES'),
      showDenyButton: true,
      showConfirmButton: true,
      denyButtonText: this.translateService.instant('NO'),
      reverseButtons: true,
      customClass: {
        denyButton: 'deny-button',
        confirmButton: 'confirm-button',
      }
    }).then(result => {
      if (result.isConfirmed) {
        const data = {
          status: 2 // 2 = Rejected Status
        };
        this.requestReceiptHttpService.update(this.depositData.deposit_id, data).pipe(
          tap(res => {
            this.historyDataService.triggerDataUpdate();
          }),
          catchError(err => {
            throw err;
          })
        ).subscribe();
      }
    });
  }

  checkReceiptAvailable(type: number, receipts = []) {
    var haveReceipt = false;
    if (receipts) {
      if (type == 1) {
        receipts.forEach(item => {
          if (item?.content.length > 0) {
            haveReceipt = true;
          }
        });
      } else {
        receipts.forEach(item => {
          if (item?.receipt_image.length > 0) {
            haveReceipt = true;
          }
        });
      }
    }

    return haveReceipt;
  }

}

