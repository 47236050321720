import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { PortalMemberProfile } from '@core/models/portal-member-profile.model';
import { ProfilePassword } from '@core/models/profile-password.model';
import { Observable, Subject } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { PortalVerification } from './../models/portal-verification.model';
import { SKIP_ERROR_INTERCEPTOR_HEADER } from 'app/utils/constants';
import { EventEmitterService } from './event-emitter.service';

@Injectable({
  providedIn: 'root'
})
export class PortalMemberProfileHttpService {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  isSuccess: boolean;

  constructor(
    private http: HttpClient,
    private eventEmitterService: EventEmitterService,
  ) { }

  getAll(): Observable<PortalMemberProfile[]> {
    return this.http.get<ApiResponse>('/profile').pipe(
      map(res => res.data),
      tap(res => {
        this.updateLocalUserData(res.rows);
      })
    );
  }

  getProfile(): Observable<PortalMemberProfile> {
    return this.http.get<ApiResponse>('/profile').pipe(
      map(res => res.data.rows),
      tap(res => {
        this.updateLocalUserData(res);
      })
    );
  }

  updateProfile(profile: PortalMemberProfile): Observable<PortalMemberProfile> {
    return this.http.put<ApiResponse>(`/profile`, profile).pipe(
      tap(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
        this.updateLocalUserDataViaProfile(res);
      }),
      concatMap((entity) => this.http.get(`/profile`).pipe(
        map((row: ApiResponse) => row.data.rows),
        tap(res => {

          // Complete or reset
          this.messages$.next(null)
          this.isSuccess = false;
        })
      ))
    );
  }

  updatePassword(profilePassword: ProfilePassword) {
    return this.http.post<ApiResponse>(`/changepassword`, profilePassword).pipe(
      tap(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      }),
      map(res => res.data)
    );
  }

  requestEmail(email: string) {
    return this.http.get<ApiResponse>(`/otp/email/request?email=${email}`);
  }

  updateICVerification(verification: PortalVerification) {
    return this.http.put<ApiResponse>(`/profile/verification`, verification).pipe(
      tap(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      }),
      map(res => res.data)
    );
  }

  updateSmartTransfer() {
    return this.http.post<ApiResponse>(`/transfer/smartTransfer`, null).pipe(
      tap(res => {
        this.messages$.next(res.message);
        const userData = JSON.parse(localStorage.getItem('user_data'));
        const newUserData = {
          ...userData,
          smart_transfer: res.data.rows.smart_transfer
        };
        localStorage.setItem('user_data', JSON.stringify(newUserData));
      }),
      map(res => res.data.rows)
    );
  }

  updateSettings(data: any) {
    return this.http.post<ApiResponse>(`/settings`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  updateLocalUserData(res: any) {
    const userData = JSON.parse(localStorage.getItem('user_data'));
    if (userData !== null) {
      Object.keys(userData).forEach(function (key) {
        if(res[key]){ // SET NEW DATA
          userData[key] = res[key];
        }
      });
      localStorage.setItem('user_data', JSON.stringify(userData));
      this.eventEmitterService.onUpdateMemberGroupDetails();
    }
  }
  
  updateShowRewards(data: any){
    return this.http.post<ApiResponse>(`/showreward`, data).pipe(
      tap(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      }),
    );
  }

  getRewardDetails(){
    return this.http.get<ApiResponse>(`/getrewarddetails`).pipe(
      map(res => res.data),
    );
  }

  updateLocalUserDataViaProfile(res: any) {
    // UPDATE DATA ON LOCAL STORAGE
    const userData = JSON.parse(localStorage.getItem('user_data'));
    if (userData !== null) {
      let newUserData = {
        ...userData,
        name: res.data.rows.name
      };

      if(res.data.rows.email){
        newUserData = {...newUserData, email: res.data.rows.email};
      }

      if(res.data.rows.date_of_birth){
        newUserData = {...newUserData, date_of_birth: res.data.rows.date_of_birth};
      }

      localStorage.setItem('user_data', JSON.stringify(newUserData));
    }
  }

  validateEmail(data: any) {
    const headers = {
      [SKIP_ERROR_INTERCEPTOR_HEADER]: 'true'
    };

    return this.http.post<ApiResponse>('/validate/email', data, { headers });
  }
}
