import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthData } from '@core/models/auth-data';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DashboardService } from '@core/services/dashboard.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { logout } from '@core/store/auth/auth.actions';
import { isLoggedIn, loggedUser } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '@store/reducers';
import { forkJoin, Observable, Subject, Subscription, zip } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import Swal, { SweetAlertCustomClass } from 'sweetalert2';
import { RestoreInfoComponent } from './dialogs/restore-info/restore-info.component';
import svgIconList from 'assets/icons.json';
import { MemberBalance } from '@core/models/member-balance.model';
import { LoadingService } from '@core/services/loading-service.service';

@Component({
  selector: 'app-wallet-bar',
  templateUrl: './wallet-bar.component.html',
  styleUrls: ['./wallet-bar.component.scss']
})
export class WalletBarComponent implements OnInit, OnDestroy {
  svg: any = svgIconList;

  memberBalance: number;
  memberUsername$ = this.dashboardService.memberUsername$;
  showOnCurrentPage: Boolean;

  memberCurrency = '';

  loggedUser$: Observable<AuthData>;
  isLoggedIn$: Observable<boolean>;
  isClicked = false;
  memberGroupColor: any;
  
  // Balance Variable
  wallet: MemberBalance;
  gameProvidersBalance: { rows: [any], total_game_balance: 0 }; // With balance

  // Other varible
  providerBalancesIsLoading: number = 0;
  memberBalanceIsLoading: number = 0;

  isDemo$ = this.gameProviderHttpService.isDemo$;
  customClass: SweetAlertCustomClass = {
    popup: 'customPopup',
    content: 'customContent'
  };
  doNotShowRestoreInfo = sessionStorage.getItem('doNotShowRestoreInfo') === null ? false : JSON.parse(sessionStorage.getItem('doNotShowRestoreInfo'));

  messages$ = new Subject<any[]>();

  private subscription = new Subscription();
  private subscriptions: Subscription[] = [];

  constructor(
    private dashboardService: DashboardService,
    private walletHttpService: WalletHttpService,
    private store: Store<AppState>,
    public loadingBar: LoadingBarService,
    private authService: AuthHttpService,
    private transferService: PortalTransferHttpService,
    private router: Router,
    private gameProviderHttpService: GameProviderHttpService,
    private gameCategoryHttpService: GameCategoryHttpService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private eventEmitterService: EventEmitterService,
    private loadingService: LoadingService,
  ) { 
    // Subscribe to loading service providerBalancesLoadingBehaviourSubject to get update on loading status
    this.subscriptions.push(
      this.loadingService.providerBalancesLoadingBehaviourSubject.subscribe(val => {
        this.providerBalancesIsLoading = val;
      })
    );
    // Subscribe to loading service providerBalancesLoadingBehaviourSubject to get update on loading status
    this.subscriptions.push(
      this.loadingService.memberBalanceLoadingBehaviourSubject.subscribe(val => {
        this.memberBalanceIsLoading = val;
      })
    );
    // Subscribe to get push update for games balance
    this.subscriptions.push(
      this.gameCategoryHttpService.gameProvidersBalance.subscribe(res => {
        this.gameProvidersBalance = res;
      })
    );
  }

  ngOnInit() {
    this.checkMemberGroupLevel();
    if (this.router.url === '/member/settings' || this.router.url === '/member/wallet' || this.router.url === '/member/reward' || (this.router.url).startsWith('/member/message/') || (this.router.url).startsWith('/member/history/') || this.router.url === '/member/verification-info') {
      this.showOnCurrentPage = false;
    } else {
      this.showOnCurrentPage = true;
      this.loggedUser$ = this.store.pipe(
        select(loggedUser),
        tap((user) => {
          this.dashboardService.refreshMemberName(user.username);
        })
      );
      this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
      this.loggedUser$.subscribe(res => {
        if (res !== null) {
          this.setWalletDetails();
        }
      });
    }
    this.subscriptions.push(this.eventEmitterService.restoreInfoEmitter.subscribe((checked) => {
      this.doNotShowRestoreInfo = checked;
    }));
    this.subscriptions.push(this.eventEmitterService.restoreEmitter.subscribe(() => {
      this.restore();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  onRestoreInfo() {
    this.dialog.open(RestoreInfoComponent);
  }

  async onRefreshBalance() {
    this.isClicked = true;
    await this.getWalletDetails();
    this.isClicked = false;
  }

  get isTokenExpired() {
    if (!this.authService.isTokenExpired()) {
      return false;
    }
    this.store.dispatch(logout());
    localStorage.setItem('redirectToLogin', '1');
    return true;
  }

  onRestore() {
    if (!this.doNotShowRestoreInfo) {
      this.onRestoreInfo();
    } else {
      this.restore();
    }
  }

  private async setWalletDetails() {
    if (!this.authService.isTokenExpired()) {
      if (this.walletHttpService.memberBalance === undefined) {
        await this.getWalletDetails();
      }
      this.subscriptions.push(
        this.walletHttpService.memberBalance.subscribe((res) => {
          this.memberBalance = res.balance;
          this.memberCurrency = res.currency_code ? res.currency_code : '$';
          this.isClicked = false;
        })
      );
    }
  }

  private getWalletDetails() {
    return new Promise<void>((resolve, reject) => {
      this.walletHttpService.getMemberBalance().subscribe(() => resolve());
    });
  }

  private restore() {
    // Follow UP side panel restore method
    if (this.providerBalancesIsLoading == 0 && this.memberBalanceIsLoading == 0) {
      this.loadingBar.start();
      const transfers = [];
      const providersWithOngoingPromo = [];
      if (this.gameProvidersBalance.total_game_balance > 0) {
        this.gameProvidersBalance.rows.map(row => {
          if (row.balance > 0.00 && row.status === 1) {
            // If there is game provider with on ongoing promo we won't help user auto unlock during restore, user need to manual transfer out from game wallet to main wallet
            if (row.ongoing_promo_with_max_transfer_out == 1) {
              providersWithOngoingPromo.push(row.name);
            } else {
              transfers.push(this.transferService.transferBy(row.id, 'all', 2));
            }
          }
        });
      }

      this.handleTransfers(transfers, providersWithOngoingPromo);
    }
  }

  private async handleTransfers(transfers: any[], providersWithOngoingPromo: any[]) {
    const showSwalDialog = async (): Promise<void> => {
      if (providersWithOngoingPromo.length > 0) {
        const providersHtml = providersWithOngoingPromo
          .map((provider, index) => `
            <div class="col-12 d-flex justify-content-between list-item">
              <span class="w-100 d-flex align-items-center">${index + 1}. ${provider}</span>
            </div>
          `).join('');

        await Swal.fire({
          html: `
            <div class="msg-icon-info">${this.svg.dangerIcon}</div>
            <div class="text-center m-t-20">
              <p class="m-b-0">${this.translateService.instant('Successfully perform restore for eligible game providers.')}</p>
              <p class="m-b-20">${this.translateService.instant('Unable to perform the restore action for the following game provider(s) due to ongoing promotion:')}</p>
              <div class="row custom-gameprovider-row-height m-l-10 m-r-10">
                ${providersHtml}
              </div>
            </div>
          `,
          showDenyButton: false,
          showConfirmButton: true,
          confirmButtonText: this.translateService.instant('OK'),
          customClass: {
            confirmButton: 'confirm-button',
          },
        });
      } 
    };

    if (transfers.length === 0) {
      await showSwalDialog();
      this.loadingBar.complete();
      this.messages$.next([this.translateService.instant('All credits are in Main Wallet')]);
    }

    zip(...transfers).subscribe({
      complete: async () => {
        await (async () => {
          this.onRefreshBalance();
          this.getListPromoAndGameProvider();
          this.loadingBar.complete();
          await showSwalDialog();
          this.messages$.next([this.translateService.instant('Restore Successfully')]);
        })();
      },
      error: (error) => {
        this.loadingBar.complete();
        throw error;
      },
    });
  }

  private getListPromoAndGameProvider() {
    this.gameCategoryHttpService.getGameCategoryListWithPromo(true).subscribe();
  }

  private onSwal() {
    Swal.fire({
      icon: 'success',
      html: '<div class="text-center">' + this.translateService.instant('Restore Successfully') + ' </div>',
      toast: true,
      showConfirmButton: false,
      customClass: this.customClass,
      position: 'top',
      timer: 3000,
      hideClass: {
        popup: `animate__animated animate__bounceOutUp`
      },
    });
  }

  checkMemberGroupLevel() {
    const memberGroup = JSON.parse(localStorage.getItem('user_data')).member_group.code?.toLowerCase();
    switch (true) {
      case /bronze/i.test(memberGroup):
        this.memberGroupColor = '1';
        break;

      case /silver/i.test(memberGroup):
        this.memberGroupColor = '2';
        break;

      case /gold/i.test(memberGroup):
        this.memberGroupColor = '3';
        break;

      case /platinum/i.test(memberGroup):
        this.memberGroupColor = '4';
        break;

      case /diamond/i.test(memberGroup):
        this.memberGroupColor = '5';
        break;

      default:
        this.memberGroupColor = '0';
        break;
    }
  }
}
