<div class="container clearfix zi2 bg-color-dark-blue" *ngIf="(isLoggedIn$ | async) && showOnCurrentPage">

    <div class="row box-shadow wallet-bar-height" id="wallet-bar">

        <div class="balance-box" style="display: flex;"  (click)="onRefreshBalance()" [ngClass]="'levelColor' + memberGroupColor">
            <ng-container *ngIf="!isTokenExpired">
                <div class="wallet-amount">
                    <h6> {{ 'Main Wallet' | translate }} ({{ memberCurrency }})<br>
                    <span>{{ memberBalance | number : '1.2-2' }}</span>
                    </h6>
                </div>
                <div class="wallet-refresh">
                    <span [innerHTML]="svg.refreshIcon | safeHtml" [ngClass]="{'refresh-spin': isClicked}"></span>
                </div>
            </ng-container>
        </div>
        <div class="transaction-box">
            <ul class="wallet-navi">
                <li>
                    <a (click)="onRestore()">
                        <span [innerHTML]="svg.restoreIcon | safeHtml"></span>
                        <h6>{{ 'Restore' | translate }}</h6>
                    </a>
                </li>
                <li>
                    <a routerLink="/member/deposit">
                        <span [innerHTML]="svg.depositIcon | safeHtml"></span>
                        <h6>{{ 'Deposit' | translate }}</h6>
                    </a>
                </li>

                <li>
                    <a routerLink="/member/transfer">
                        <span [innerHTML]="svg.transferIcon | safeHtml"></span>
                        <h6>{{ 'Transfer' | translate }}</h6>
                    </a>
                </li>
                <li>
                    <a routerLink="/member/withdraw">
                        <span [innerHTML]="svg.withdrawIcon | safeHtml"></span>
                        <h6>{{ 'Withdraw' | translate }}</h6>
                    </a>
                </li>
            </ul>
        </div>

    </div>
</div>


<app-swal-alert [message]="messages$ | async" [success]="true" [timer]="5000"></app-swal-alert>