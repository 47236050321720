import { Subscription } from 'rxjs/internal/Subscription';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MemberBalance } from '@core/models/member-balance.model';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { TranslateService } from '@ngx-translate/core';
import { WalletModalComponent } from '@shared/wallet-modal/wallet-modal.component';
import { of, zip, forkJoin } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { RequestReceiptHttpService } from '@core/services/request-receipt-http.service';
import { RequestReceipt } from '@core/models/request-receipt.model';
import { LoadingService } from '@core/services/loading-service.service';
import svgIconList from 'assets/icons.json';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit, OnDestroy {
  svg: any = svgIconList;

  // Notification Variable
  messages$ = this.portalMemberProfileHttpService.messages$;
  newRequestIncoming = this.requestReceiptHttpService.newRequestIncoming;
  depositData: RequestReceipt = RequestReceiptHttpService.requestReceipt;

  // Balance Variable
  wallet: MemberBalance;
  gameProvidersBalance: { rows: [any], total_game_balance: 0 }; // With balance
  totalGamesBalancePercentage = 0;
  totalBalance = 0;
  refreshIsClicked = false;

  // Smart Transfer Variable
  smartTransfer = false;
  disabledSmartTransferSwitch = false;

  // Other varible
  providerBalancesIsLoading: number = 0;
  memberBalanceIsLoading: number = 0;

  // To hold all subscription and unsubscribe when leaving the page 
  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private transferService: PortalTransferHttpService,
    private portalMemberProfileHttpService: PortalMemberProfileHttpService,
    private gameCategoryHttpService: GameCategoryHttpService,
    private walletHttpService: WalletHttpService,
    private translateService: TranslateService,
    private requestReceiptHttpService: RequestReceiptHttpService,
    private loadingService: LoadingService
  ) {
    // Subscribe to loading service providerBalancesLoadingBehaviourSubject to get update on loading status
    this.subscriptions.push(
      this.loadingService.providerBalancesLoadingBehaviourSubject.subscribe(val => {
        this.providerBalancesIsLoading = val;
      })
    );
    // Subscribe to loading service providerBalancesLoadingBehaviourSubject to get update on loading status
    this.subscriptions.push(
      this.loadingService.memberBalanceLoadingBehaviourSubject.subscribe(val => {
        this.memberBalanceIsLoading = val;
      })
    );
    // Subscribe to get push update for member balance
    this.subscriptions.push(
      this.walletHttpService.memberBalance.subscribe(res => {
        this.wallet = res;
        if (this.wallet.currency_code != '-' && this.gameProvidersBalance != undefined) {
          this.getTotalBalance();
        }
      })
    );
    // Subscribe to get push update for games balance
    this.subscriptions.push(
      this.gameCategoryHttpService.gameProvidersBalance.subscribe(res => {
        this.gameProvidersBalance = res;
        if (this.gameProvidersBalance.rows.length > 0 && this.wallet != undefined) {
          this.getTotalBalance();
        }
      })
    );
    // Subscribe to get push update for request receipt 
    this.subscriptions.push(
      this.newRequestIncoming.subscribe(() => {
        this.depositData = RequestReceiptHttpService.requestReceipt;
      })
    );
  }

  ngOnInit() {
    this.getSmartTransfer();
    this.refreshBalance();
  }

  ngOnDestroy() {
    this.subscriptions.map(sb => sb.unsubscribe());
  }

  onSmartTransfer() {
    this.disabledSmartTransferSwitch = true;
    of(null).pipe(
      delay(3000), tap(() => this.disabledSmartTransferSwitch = false
      )).subscribe();
    this.portalMemberProfileHttpService.updateSmartTransfer().pipe(
      tap((res: any) => {
        this.smartTransfer = Boolean(res.smart_transfer);
      }),
      catchError((error) => {
        const smartTransferRef = this.smartTransfer;
        this.smartTransfer = null;
        of(null).pipe(
          delay(200), tap(() => this.smartTransfer = smartTransferRef
          )).subscribe();
        this.disabledSmartTransferSwitch = false;
        throw error;
      })
    ).subscribe();
  }

  onRestore() {
    if (this.providerBalancesIsLoading == 0 && this.memberBalanceIsLoading == 0) {
      const transfers = [];
      const providersWithOngoingPromo = [];
      if (this.gameProvidersBalance.total_game_balance > 0) {
        this.gameProvidersBalance.rows.map(row => {
          if (row.balance > 0.00 && row.status === 1) {
            // If there is game provider with on ongoing promo we won't help user auto unlock during restore, user need to manual transfer out from game wallet to main wallet
            if (row.ongoing_promo_with_max_transfer_out == 1) {
              providersWithOngoingPromo.push(row.name);
            } else {
              transfers.push(this.transferService.transferBy(row.id, 'all', 2));
            }
          }
        });
      }

      this.handleTransfers(transfers, providersWithOngoingPromo);
    }
  }

  private async handleTransfers(transfers: any[], providersWithOngoingPromo: any[]) {
    const showSwalDialog = async (): Promise<void> => {
      if (providersWithOngoingPromo.length > 0) {
        const providersHtml = providersWithOngoingPromo
          .map((provider, index) => `
            <div class="col-12 d-flex justify-content-between list-item">
              <span class="w-100 d-flex align-items-center">${index + 1}. ${provider}</span>
            </div>
          `).join('');

        await Swal.fire({
          html: `
            <div class="msg-icon-info">${this.svg.dangerIcon}</div>
            <div class="text-center m-t-20">
              <p class="m-b-0">${this.translateService.instant('Successfully perform restore for eligible game providers.')}</p>
              <p class="m-b-20">${this.translateService.instant('Unable to perform the restore action for the following game provider(s) due to ongoing promotion:')}</p>
              <div class="row custom-gameprovider-row-height m-l-10 m-r-10">
                ${providersHtml}
              </div>
            </div>
          `,
          showDenyButton: false,
          showConfirmButton: true,
          confirmButtonText: this.translateService.instant('OK'),
          customClass: {
            confirmButton: 'confirm-button',
          },
        });
      } 
    };

    if (transfers.length === 0) {
      await showSwalDialog();
      this.messages$.next([this.translateService.instant('All credits are in Main Wallet')]);
    }

    zip(...transfers).subscribe({
      complete: async () => {
        await (async () => {
          this.refreshBalance();
          await showSwalDialog();
          this.messages$.next([this.translateService.instant('Restore Successfully')]);
        })();
      },
      error: (error) => {
        this.refreshBalance();
        throw error;
      },
    });
  }

  onRefresh() {
    if (this.providerBalancesIsLoading == 0 && this.memberBalanceIsLoading == 0) {
      this.refreshIsClicked = true;
      this.refreshBalance();
    }
  }

  onOpenDialog() {
    const dialogRef = this.dialog.open(WalletModalComponent);
  }

  private getSmartTransfer() {
    const userData = JSON.parse(localStorage.getItem('user_data'));
    this.smartTransfer = Boolean(userData.smart_transfer);
  }

  private refreshBalance() {
    forkJoin([
      this.gameCategoryHttpService.getGameCategoryListWithPromo(true),
      this.walletHttpService.getMemberBalance()
    ]).subscribe();
  }

  private getTotalBalance() {
    this.totalBalance = (+this.wallet.balance) + (+this.gameProvidersBalance.total_game_balance);
    this.totalGamesBalancePercentage = (+this.gameProvidersBalance.total_game_balance / +this.totalBalance) * 220;
    this.refreshIsClicked = false;
  }
}
