import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Pagination } from '@core/models/pagination.model';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AppState } from '@store/reducers';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ReferralDataService } from '../../../../../../core/services/referral-data.service';
import { QRCodeElementType } from 'angularx-qrcode';
import { MatTooltip } from '@angular/material/tooltip';
import svgIconList from 'assets/icons.json';
import * as moment from 'moment';
import { ClaimRewardModalComponent } from '../../../../member/dialogs/reward-modal/claim-reward-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { DateFilterService } from '@core/services/date-filter.service';
import { FormControl, FormGroup } from '@angular/forms';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';

declare const $: any;

@Component({
  selector: 'app-referral-history',
  templateUrl: './referral-history.component.html',
  styleUrls: ['./referral-history.component.scss']
})
export class ReferralHistoryComponent implements OnInit {
  @ViewChild('tooltipCode') tooltipCode: MatTooltip;
  @ViewChild('tooltipLink') tooltipLink: MatTooltip;
  @ViewChild('tooltipQr')   tooltipQr:   MatTooltip;
  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;

  svg: any = svgIconList;
  details: any = {};
  userData = JSON.parse(localStorage.getItem('user_data'));
  currency = JSON.parse(localStorage.getItem('user_data')).currency;

  isLoggedIn$: Observable<boolean>;
  pagination: Pagination;
  pageSize = 15;
  page = 1;
  maxSize = 5;
  params = `pagination=true`;
  isLoading = false;
  history$ = [];
  userKYCstatus = localStorage.getItem('user_data') !== null ? JSON.parse(localStorage.getItem('user_data')).KYC : null;
  elementType = "canvas" as QRCodeElementType;
  qrSignUpLink: string;
  showMultiTierCommission: boolean = false;
  tabType = 'downline';
  downlineList$ = [];
  referralBonusHistory$ = [];
  commissionHistory$ = [];
  totalDownlinePlayer = 0;
  form: FormGroup;
  activeDateFilter = 'All';
  filterButtons = ['Today', 'Last 30 Days', 'Yesterday', 'Last 60 Days', 'Last 7 Days', 'All'];
  max = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  private datePickerSubscription = new Subscription();


  constructor(
    private referralDataService: ReferralDataService,
    private loadingBar: LoadingBarService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private eventEmitterService: EventEmitterService,
    private dateFilterService: DateFilterService,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.formInit();
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    this.isLoggedIn$.subscribe(res => {
      if (res) {
        setTimeout(() => {
          this.userKYCstatus = JSON.parse(localStorage.getItem('user_data')).KYC;
          if (this.userKYCstatus === 'pro'){
            this.referralDataService.getDetails().subscribe(
              (res) => {
                this.details = res;
                this.showMultiTierCommission = res['isRefCommTier'];
              },
              (err) => {
                Swal.fire({
                  html: '<i class="icon-exclamation-triangle alert-icon-fail"></i>' +
                    '<div class="text-center m-t-20">' +
                    err +
                    '</div>'
                });
              },
              () => { });
            this.onViewPageBy(false, 1);
          }
        }, 500);
      }
    });
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'start_datetime'),
      this.buildDatePicker(1, 'end_datetime'),
    ]).subscribe();
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, this.dateFilterService.dateFormat)),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      })
    );
  }

  onScroll(event) {
    if ((event.target.offsetHeight + event.target.scrollTop + 1) >= event.target.scrollHeight) {
      if (this.page < this.pagination.last_page && !this.isLoading) {
        this.isLoading = true;
        this.page++;
        this.onViewPageBy(true, this.page, this.pageSize);
      }
    }
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = key === 'start_datetime' ? moment(formData[key] + ' 00:00:00').utc().format('YYYY-MM-DD HH:mm:ss') : key === 'end_datetime' ? moment(formData[key] + ' 23:59:59').utc().format('YYYY-MM-DD HH:mm:ss') : formData[key];
      }
    });
    return fields;
  }

  onViewPageBy(preLoad?: boolean, page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();

    if (this.tabType === 'downline') {
      return this.getDownlineList(preLoad, page, pageSize, params);
    } else if (this.tabType === 'bonus') {
      return this.getReferralBonusHistory(preLoad, page, pageSize, params);
    } else if (this.tabType === 'commission') {
      return this.getCommissionHistory(preLoad, page, pageSize, params);
    }
  }

  onSubmit() {
    this.loadingBar.start();
    const data = this.filterFormFields(this.form.value);
    this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = this.params ? `&${this.params}` : '';

    if (this.tabType === 'downline') {
      return this.getDownlineList(false, 1, this.pageSize, parameters);
    } else if (this.tabType === 'bonus') {
      return this.getReferralBonusHistory(false, 1, this.pageSize, parameters);
    } else if (this.tabType === 'commission') {
      return this.getCommissionHistory(false, 1, this.pageSize, parameters);
    }
  }

  showDetails(id: number) {
    $(`#refferal-details-${id}`).prev().toggleClass('opened');
  }

  getQRSignupLink(refcode:any){
    this.qrSignUpLink = window.location.origin + '/signup?ref=' + refcode;
    return this.qrSignUpLink;
  }

  onDownloadQR(parent: any) {
    let parentElement = null

    if (this.elementType === "canvas") {
      // fetches base 64 data from canvas
      parentElement = parent.qrcElement.nativeElement
        .querySelector("canvas")
        .toDataURL("image/png")
    } else if (this.elementType === "img" || this.elementType === "url") {
      // fetches base 64 data from image
      // parentElement contains the base64 encoded image src
      // you might use to store somewhere
      parentElement = parent.qrcElement.nativeElement.querySelector("img").src
    } else {
      alert("Set elementType to 'canvas', 'img' or 'url'.")
    }

    if (parentElement) {
      // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(parentElement)
      // saves as image
      const blob = new Blob([blobData], { type: "image/png" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      // name of the file
      link.download = "angularx-qrcode"
      link.click()
    }
  }

  private convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,")
    // hold the content type
    const imageType = parts[0].split(":")[1]
    // decode base64 string
    const decodedData = window.atob(parts[1])
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType })
  }

  onCopyText(inputElement: any, type: string, param?: string) {
    const textCopy = param || inputElement.value;
    navigator.clipboard.writeText(textCopy);

    if (type === 'code') {
      this.tooltipCode.show();
      setTimeout(() => this.tooltipCode.hide(), 2000);
    } else if (type === 'qr') {
      this.tooltipQr.show();
      setTimeout(() => this.tooltipQr.hide(), 2000);
    } else {
      this.tooltipLink.show();
      setTimeout(() => this.tooltipLink.hide(), 2000);
    }
  }

  onShare(parent) {
    const navigator = window.navigator as any;
    if (navigator.share) {
      let parentElement = null

      // fetches base 64 data from canvas
      parentElement = parent.qrcElement.nativeElement
        .querySelector("canvas")
        .toDataURL("image/png")

      navigator.share({
        files: [
          new File(
            [this.convertBase64ToBlob(parentElement)],
            "QRcode.png",
            { type: "image/png" }
          ),
        ],
        title: "QR code",
        text: "Share this QR code with your friends",
      });
    }
  }

  changeTab(type: string) {
    if (this.tabType !== type) {
      this.tabType = type;
      this.form.patchValue({
        start_datetime: null,
        end_datetime: null
      });
      this.onSubmit();
    }
  }

  private getDownlineList(preLoad: boolean, page: number, pageSize: number, params: string) {
    if (!preLoad) {
      this.page = 1;
      this.downlineList$ = [];
    }
    this.isLoading = true;
    this.referralDataService.getDownlineList(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.referralDataService.pagination;
        this.totalDownlinePlayer = this.referralDataService.pagination?.total;
        res.map((row: any) => {
          this.downlineList$.push({
            ...row,
            created_at: moment(row.created_at).local(true).format('YYYY-MM-DD HH:mm')
          });
        });
        this.isLoading = false;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  private getReferralBonusHistory(preLoad: boolean, page: number, pageSize: number, params: string) {
    if (!preLoad) {
      this.page = 1;
      this.referralBonusHistory$ = [];
    }
    this.isLoading = true;
    this.referralDataService.getHistory(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.referralDataService.pagination;
        res.map((row: any) => {
          this.referralBonusHistory$.push({
            ...row,
            created_at: moment(row.created_at).local(true).format('YYYY-MM-DD HH:mm')
          });
        });
        this.isLoading = false;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  private getCommissionHistory(preLoad: boolean, page: number, pageSize: number, params: string) {
    if (!preLoad) {
      this.page = 1;
      this.commissionHistory$ = [];
    }
    this.isLoading = true;
    this.referralDataService.getCommissionHistory(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.referralDataService.pagination;
        res.map((row: any) => {
          this.commissionHistory$.push({
            ...row,
            created_at: moment(row.created_at).local(true).format('YYYY-MM-DD HH:mm')
          });
        });
        this.isLoading = false;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  onClaim(row: any){
    const dialogRef = this.dialog.open(ClaimRewardModalComponent, {
      width: '90vw',
      data: {
        reward: row
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true){
        this.onViewPageBy(false, 1);
        //update reward bubble
        this.eventEmitterService.onUpdateRewardBubble();
      }
    });
  }

  onDateTimeFilter(type?: any) {
    this.activeDateFilter = type;
    if (type === 'Today') {
      this.form.patchValue({
        start_datetime: this.dateFilterService.getToday().from,
        end_datetime: this.dateFilterService.getToday().to
      });
    } else if (type === 'Last 30 Days') {
      this.form.patchValue({
        start_datetime: this.dateFilterService.getLast30Days().from,
        end_datetime: this.dateFilterService.getLast30Days().to
      });
    } else if (type === 'Yesterday') {
      this.form.patchValue({
        start_datetime: this.dateFilterService.getYesterday().from,
        end_datetime: this.dateFilterService.getYesterday().to
      });
    } else if (type === 'Last 60 Days') {
      this.form.patchValue({
        start_datetime: this.dateFilterService.getLast60Days().from,
        end_datetime: this.dateFilterService.getLast60Days().to
      });
    } else if (type === 'Last 7 Days') {
      this.form.patchValue({
        start_datetime: this.dateFilterService.getLast7Days().from,
        end_datetime: this.dateFilterService.getLast7Days().to
      });
    } else {
      this.form.patchValue({
        start_datetime: null,
        end_datetime: null
      });
    }
    this.onSubmit();
  }

  private formInit() {
    this.form = new FormGroup({
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null)
    });
  }

  onFormDate(formKey: string, separator = '/') {
    const value = this.form.get(formKey).value;
    if (value !== null) {
      let date = this.form.get(formKey).value.replace(/[^0-9]+/g, "");
      let newDate = '';
      for (var i = 0; i < date.length; i++) {
        if (i === 4 || i === 6) {
          newDate += separator;
        }
        newDate += date[i];
      }
      this.form.patchValue({ [formKey]: newDate })
    }
  }
}
